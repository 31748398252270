import { useAuth } from '@/composables/auth';

export default defineNuxtRouteMiddleware(async (from, to) => {
  if (process.client) {
    return;
  }

  if (to.path === '/') {
    return navigateTo('/login');
  }

  const router = useRouter();
  const { auth } = useAuth();
  const appConfig = useAppConfig();

  // ログインチェック
  if (auth.value === undefined || !auth.value.logedIn || !auth.value.userinfo) {
    router.push('/login');
  } else {
    let accept = false;

    for (const permission in auth.value.userinfo.permissions) {
      const targetUrl = appConfig.rolePath[permission];
      // アクセスURL
      if (to.path.startsWith(targetUrl)) {
        accept = true;
        break;
      }
    }

    // 権限チェック
    if (!accept) {
      throw createError({
        statusCode: 403,
        message: '権限がありません。',
        fatal: true
      });
    }
  }
});
